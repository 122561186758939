<template>
    <collective-detail
      breadcrumb-route="backoffice-organizations"
      item-type="organizations"
      item-type-single="organization"
    />
  </template>
  
  <script>
  import CollectiveDetail from '@/views/apps/collectives/components/CollectiveDetail.vue';
  
  export default {
    name: 'BackofficeOrganizationsDetails',
    components: {
      CollectiveDetail,
    },
  };
  </script>
  